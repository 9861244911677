import React, { useRef } from "react";
import { CommissionArrangeModelByStaff } from "@/models";
import Image from "next/image";
import { currencyStr } from "@/utils";
import { useStore, setTipForStaff } from "@/store";

export interface TipItemProps {
  commissionArrangementByStaff: CommissionArrangeModelByStaff;
  handleSetKeyBoardTip: (staffId?: string, value?: string) => void;
  setIsOpenKeyBoard: (value: boolean) => void;
  setCurrentStaffTyping: (staffId?: string) => void;
}

export function TipItem(props: TipItemProps) {
  const {
    commissionArrangementByStaff,
    setIsOpenKeyBoard,
    setCurrentStaffTyping,
  } = props;
  const { staff, commissionArranges } = commissionArrangementByStaff;
  const [state, dispatch] = useStore();
  const { raitingSetting, invoiceStaffRaiting } = state;

  const tipElementRef = useRef<HTMLInputElement>(null);

  const currentInvoiceStaffRaiting = invoiceStaffRaiting?.find(
    (item) => item.staffId == staff?.id
  );
  const currentIcon = raitingSetting?.staffRatingItem?.find(
    (item) => item.id == currentInvoiceStaffRaiting?.ratingItemId
  );

  const handleTipForStaff = (money?: number) => {
    dispatch(
      setTipForStaff({
        staffId: staff?.id,
        tip: money,
        isKeyBoard: false,
      })
    );
  };

  const handleCancelTip = () => {
    dispatch(
      setTipForStaff({
        staffId: staff?.id,
        tip: 0,
      })
    );
  };

  const tipsOrderByValue = raitingSetting?.staffRatingTip?.sort((a,b)=> (a?.money ?? 0) - (b?.money ?? 0));

  return (
    <div className="px-[42px] py-[28px] border-t-[3px] border-solid border-[#E5E5E5]">
      <div className="mb-[12px] flex justify-between items-center h-[50px]">
        <h3 className="text-[#0068FF]">{staff?.name}</h3>
        {currentIcon?.iconUrl && (
          <div
            className={`min-w-[136px] h-[48px] rounded-[12px] center pl-[10px] pr-[12px]`}
            style={{ backgroundColor: `#${currentIcon.color}` }}
          >
            <Image
              src={currentIcon?.iconUrl}
              alt="icon-img"
              width={35}
              height={35}
            />
            <h5 className="ml-[12px] text-white mb-0">
              {currentIcon?.description}
            </h5>
          </div>
        )}
      </div>
      <div className="flex flex-wrap mb-[36px]">
        {commissionArranges?.map((item, _, _this) => {
          return _this.length <= 3 ? (
            <h3 className="text-primary mb-[6px] w-full" key={item.id}>
              - {item.objectName}
            </h3>
          ) : (
            <h3 className="text-primary mb-[6px] w-2/4" key={item.id}>
              - {item.objectName}
            </h3>
          );
        })}
      </div>
      <div className="flex flex-wrap">
        {tipsOrderByValue?.map((item) => {
          const isActive =
            !currentInvoiceStaffRaiting?.isKeyBoardTip &&
            currentInvoiceStaffRaiting?.tip == item?.money;
          return (
            <div
              className="w-[168px] h-[114px] rounded-[12px] mr-4 mb-6 bg-[#F1F7FF] flex justify-center items-center cursor-pointer mb-[6px] relative overflow-hidden"
              key={item.id}
            >
              {isActive && (
                <div
                  className="absolute top-0 right-0 w-[80px] h-[33px] bg-[#C0DBFF] center"
                  onClick={() => handleCancelTip()}
                >
                  Bỏ chọn
                </div>
              )}
              <h2
                className={`text-primary ${
                  isActive ? "text-[#0068FF]" : ""
                } mb-0`}
                onClick={() => handleTipForStaff(item.money)}
              >
                {currencyStr(item.money)}
              </h2>
              <span
                className={`text-2xl font-bold mt-[6px] ${
                  isActive ? "text-[#0068FF]" : ""
                }`}
              >
                đ
              </span>
            </div>
          );
        })}

        <div
          onClick={() => {
            setIsOpenKeyBoard(true);
            setCurrentStaffTyping(staff?.id);
            setTimeout(() => {
              if (!tipElementRef.current) {
                return;
              }

              const yOffset = 250;
              const y =
                tipElementRef.current?.getBoundingClientRect().top +
                window.pageYOffset -
                yOffset;
              window.scrollTo({ top: y, behavior: "smooth" });
            }, 200);
          }}
          className="cursor-text"
        >
          <div className="w-[167px] cursor-text h-[114px] rounded-[12px] bg-[#F1F7FF] flex justify-center items-center">
            {currentInvoiceStaffRaiting?.isKeyBoardTip ? (
              <span className="text-[#0068FF] text-input text-4xl">
                {currencyStr(currentInvoiceStaffRaiting.tip)}đ
              </span>
            ) : (
              <span className="text-[#808B9F] text-input text-4xl ">Nhập</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
