import React, { useId, useState } from "react";
import { CommissionArrangeModelByStaff, RaitingSettingModel } from "@/models";
import Image from "next/image";
import {
  useStore,
  setIconRaiting,
  setFeedbackRaiting,
  unSetFeedbackRaiting,
  setOtherFeedbackRaiting,
} from "@/store";


const iconsRatingDefault = ['/statics/rating_icons/bad.png', '/statics/rating_icons/normal.png', '/statics/rating_icons/good.png', '/statics/rating_icons/very_good.png' ]

export interface RaitingItemProps {
  commissionArrangementByStaff: CommissionArrangeModelByStaff;
  updateRatingSetting: RaitingSettingModel | undefined
}

export function RaitingItem(props: RaitingItemProps) {
  const idGenarator = useId();
  const [isOtherFeedback, setIsOtherFeedback] = useState(false);
  const { commissionArrangementByStaff, updateRatingSetting } = props;

  const staffId = commissionArrangementByStaff.staff?.id;
  const [state, dispatch] = useStore();
  const { raitingSetting, invoiceStaffRaiting } = state;

  const ratingSetting =  updateRatingSetting || raitingSetting;

  const currentInvoiceStaffRaiting = invoiceStaffRaiting?.find(
    (item) => item.staffId == staffId
  );

  const handleRaitingIcon = (iconId?: string) => {
    if (iconId) {
      currentInvoiceStaffRaiting?.ratingItemId !== iconId &&
        dispatch(
          setIconRaiting({
            staffId: staffId,
            ratingItemId: iconId,
          })
        );
    }
  };

  const handleFeedbackRaiting = (feedbackId?: string) => {
    if (feedbackId) {
      !!currentInvoiceStaffRaiting?.invoiceStaffRatingDefaultFeedback?.find(
        (item) => item.defaultFeedbackId == feedbackId
      )
        ? dispatch(
            unSetFeedbackRaiting({
              staffId: staffId,
              default_feedback_id: feedbackId,
            })
          )
        : dispatch(
            setFeedbackRaiting({
              staffId: staffId,
              default_feedback_id: feedbackId,
            })
          );
    }
  };

  const handleChangeOtheFeedback = (otherFeedback: string) => {
    dispatch(
      setOtherFeedbackRaiting({
        staffId: staffId,
        otherFeedback: otherFeedback,
      })
    );
  };

  const handleTogleOtheFeedback = (checked: boolean) => {
    if (checked) {
      setIsOtherFeedback(true);
    } else {
      setIsOtherFeedback(false);
      dispatch(
        setOtherFeedbackRaiting({
          staffId: staffId,
          otherFeedback: "",
        })
      );
    }
  };

  const currentIconRating = raitingSetting?.staffRatingItem?.find(
    (i) => currentInvoiceStaffRaiting?.ratingItemId == i.id
  );

  return (
    <div className="px-[42px] py-[28px] border-t-[3px] border-solid border-[#E5E5E5]">
      <h3 className="text-[#0068FF] mb-[25px]">
        {commissionArrangementByStaff?.staff?.name}
      </h3>
      <div className="flex flex-wrap mb-[36px]">
        {commissionArrangementByStaff?.commissionArranges?.map(
          (item, _, _this) => {
            return _this.length <= 3 ? (
              <h3 className="text-primary mb-[6px] w-full" key={item.id}>
                - {item.objectName}
              </h3>
            ) : (
              <h3 className="text-primary mb-[6px] w-2/4" key={item.id}>
                - {item.objectName}
              </h3>
            );
          }
        )}
      </div>
      <div className="flex justify-between">
        {ratingSetting?.staffRatingItem?.map((icon, index) => {
          const isActiveRaiting =
            icon.id == currentInvoiceStaffRaiting?.ratingItemId;
          return (
            <div
              key={icon?.id}
              className={`w-[210px] h-[240px] rounded-[16px] border border-solid border-[#DEE2EA] center flex-col cursor-pointer`}
              style={{
                backgroundColor: isActiveRaiting ? `#${icon?.color}` : "#fff",
              }}
              onClick={() => {
                handleRaitingIcon(icon.id);
              }}
            >
                <Image
                  src={icon?.iconUrl || iconsRatingDefault[index]}
                  alt="icon-img"
                  width={135}
                  height={138}
                />
              <span
                className={`text-[31px] font-medium mt-[24px] ${
                  isActiveRaiting ? "text-white" : "text-primary"
                }`}
              >
                {icon?.description}
              </span>
            </div>
          );
        })}
      </div>
      {currentIconRating?.isShowFeedback && (
        <div className="mt-[36px]">
          <h3 className="text-primary mb-[6px] font-medium">Thư mục góp ý</h3>
          {ratingSetting?.defaultFeedback?.map((item) => {
            return (
              <div className="flex mt-[30px] cursor-pointer" key={item.id}>
                <input
                  id={`${item?.id}-${idGenarator}`}
                  type="checkbox"
                  name="feedback"
                  value={item?.id}
                  className="w-[42px] h-[42px] cursor-pointer rounded-[8px] border-[1.5px] border-solid border-[#292D32]"
                  onChange={(e) => handleFeedbackRaiting(e.target.value)}
                />
                <label
                  className="rounded-[8px] cursor-pointer"
                  htmlFor={`${item?.id}-${idGenarator}`}
                >
                  <h3 className="text-primary mb-[6px] font-medium ml-[18px]">
                    {item?.description}
                  </h3>
                </label>
              </div>
            );
          })}
          <div className="flex mt-[30px]">
            <input
              id={`orther-feddback-${idGenarator}`}
              type="checkbox"
              name="otherFeedback"
              className="w-[42px] h-[42px] cursor-pointer rounded-[8px] border-[1.5px] border-solid border-[#292D32]"
              // hidden={true}
              onChange={(e) => {
                handleTogleOtheFeedback(e.target.checked);
              }}
            />
            <label
              className="rounded-[8px] cursor-pointer flex"
              htmlFor={`orther-feddback-${idGenarator}`}
            >
              <h3 className="text-primary mb-[6px] font-medium ml-[18px]">
                Khác:{" "}
              </h3>
              <input
                type="text"
                placeholder="Nhập lý do tại đây..."
                className="placeholde:text-suggest text-input text-primary mb-[6px] font-medium ml-[18px]"
                disabled={!isOtherFeedback}
                onChange={(e) => {
                  handleChangeOtheFeedback(e.target.value);
                }}
                value={currentInvoiceStaffRaiting?.otherFeedback}
              />
            </label>
          </div>
        </div>
      )}
    </div>
  );
}
