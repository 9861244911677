import { SalonModel } from "@/models"

export const salonIdKey = 'salonId'
export const salonKey = 'salon'
export const salonBranchIdKey = 'salonBranchId'
export const invoiceIdKey = 'invoiceId'
export const salonBranchAddressKey= 'currentSalonBranchAddress'

export type localStorageValue = string | undefined | null


export const setSalonId = (salonId: string) => {
    localStorage.setItem(
        salonIdKey,
        salonId
    );
}


export const getSalonId = (): localStorageValue => {
    if(typeof window !== 'undefined'){
        return localStorage.getItem(salonIdKey)
    }
}


export const setSalonLocalStorage = (salon: SalonModel) => {
    if (salon) {
        localStorage.setItem(
            salonKey,
            JSON.stringify(salon)
        );
    }

}


export const getSalonLocalStorage = (): SalonModel | undefined => {
    if(typeof window !== 'undefined'){
        const salon = localStorage.getItem(salonKey)
        return salon ? JSON.parse(salon as string) : null
    }
}



export const setSalonBranchId = (salonBranchId: string) => {
    localStorage.setItem(
        salonBranchIdKey,
        salonBranchId
    );
}


export const getSalonBranchId = (): localStorageValue => {
    if(typeof window !== 'undefined'){
        return localStorage.getItem(salonBranchIdKey)
    }
}


export const setInvoiceId = (invoiceId: string) => {
    localStorage.setItem(
        invoiceIdKey,
        invoiceId
    );
}


export const getInvoiceId = (): string => {
    return localStorage.getItem(invoiceIdKey) as string
}

export const clearLocalStorage = () => {
    localStorage.removeItem(salonIdKey)
    localStorage.removeItem(salonKey)
    localStorage.removeItem(salonBranchIdKey)
    localStorage.removeItem(invoiceIdKey)
    localStorage.removeItem(salonBranchAddressKey)

}